@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 64px;
  align-items: center;
  padding: 80% 0 24px 0;
  & img {
    object-position: center top;
    object-fit: contain;
  }
  & .header {
    & h1 {
      white-space: break-spaces;
      font-weight: 100;
      text-align: center;
      padding: 8px 0;
    }
    justify-items: center;
  }
  @media (--viewportMedium) {
    padding: 32px 0;
    flex-direction: row;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-end;
    padding-right: 64px;
    & img {
      object-position: 50% 0%;
      object-fit: cover;
    }
    & .header {
      & h1 {
        padding: 24px 0;
      }
    }
  }
}

.buy {
  composes: buttonSecondary from global;
  background: transparent;
  color: var(--colorGrey900);
  border: 1px solid var(--colorGrey900);
  border-radius: 50px;
  margin-bottom: 24px;
  max-width: 240px;
  &:hover {
    background: transparent;
    border: 1px solid var(--colorBlack);
  }
}

.sell {
  composes: buttonSecondary from global;
  background: transparent;
  color: var(--colorGrey900);
  border: 1px solid var(--colorGrey900);
  border-radius: 50px;
  max-width: 240px;
  &:hover {
    background: transparent;
    border: 1px solid var(--colorBlack);
  }
}

.logo {
  height: 48px;
  width: auto;
  padding-right: 8px;
  @media (--viewportMedium) {
    height: 64px;
    padding-right: 18px;
  }
}