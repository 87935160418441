.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
  & a {
    cursor: pointer;
  }
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
