@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  justify-items: center;
}

.main {
  composes: baseColumn;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.howItWorks {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 0 48px;

  @media (--viewportMedium) { 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
  }
}

.header {
  font-size: 24px;
  line-height: 1.2;
  margin: 12px 0;
}

.description {
  font-size: 16px;
  color: #7A7D85;
  line-height: 1.2;
  margin: 12px 0;
  font-weight: var(--fontWeightRegular);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  & img {
    height: 128px;
    width: auto;
  }
}