@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: 30px;
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}
.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(32px);

  @media (--viewportMedium) {
    margin-right: 32px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(
      max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 32px), 0px)
    );
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4);
  }
}

.header {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  align-items: center;
  gap: 48px;
  composes: h3 from global;
  font-weight: 300;
}

.headerLink {
  cursor: pointer;
}

.seeAll {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorGrey100);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  border-radius: .375rem;
}
